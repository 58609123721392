import { warehouseCode, calcularStockVendible } from "./utils";
import { getStocks } from "@suttyweb/hyperpop";

document.addEventListener("turbo:load", async () => {
  if (!window.headers) return;

  if (document.querySelectorAll("[data-quantity-input-sku]").length === 0) return;

  for (const inputEl of document.querySelectorAll(
    "[data-quantity-input-sku]"
  )) {
    inputEl.disabled = true;
  }

  const stocks = await getStocks(
    process.env.TANGO_HOST,
    window.headers,
    {
      warehouseCode,
    }
  );

  for (const inputEl of document.querySelectorAll(
    "[data-quantity-input-sku]"
  )) {
    let unidadesPorPallet = parseInt(inputEl.dataset.unidadesPorPallet);
    if (isNaN(unidadesPorPallet)) unidadesPorPallet = 1;

    const sku = inputEl.dataset.quantityInputSku;
    const stockObj = stocks.Data.find((stockObj) => stockObj.SKUCode === sku);
    const stock =
      stockObj && Math.floor(calcularStockVendible(stockObj, unidadesPorPallet) * 0.1);
    if (stock && stock > 0) {
      inputEl.max = stock;
      inputEl.disabled = false;
    } else {
      inputEl.placeholder = "No hay stock disponible";
    }

    for (const submitEl of document.querySelectorAll(`[data-submit="${sku}"]`)) {
      submitEl.disabled = !(stock && stock > 0);
    }
  }
});
